// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("IRANSansX-Bold.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("IRANSansX-Bold.woff2", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("IRANSansX-Regular.woff", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("IRANSansX-Regular.woff2", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\r\n\tfont-family: IRANSansX;\r\n\tfont-style: normal;\r\n\tfont-weight: 700;\r\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format('woff'),   \r\n\turl(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('woff2');\t \r\n}\r\n\r\n@font-face {\r\n\tfont-family: IRANSansX;\r\n\tfont-style: normal;\r\n\tfont-weight: 400;\r\n\tsrc: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format('woff'),   \r\n\turl(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format('woff2');\t\r\n}\r\n\r\n\r\n", "",{"version":3,"sources":["webpack://./src/fonts/Font.css"],"names":[],"mappings":"AAAA;CACC,sBAAsB;CACtB,kBAAkB;CAClB,gBAAgB;CAChB;wDAC2C;AAC5C;;AAEA;CACC,sBAAsB;CACtB,kBAAkB;CAClB,gBAAgB;CAChB;wDAC8C;AAC/C","sourcesContent":["@font-face {\r\n\tfont-family: IRANSansX;\r\n\tfont-style: normal;\r\n\tfont-weight: 700;\r\n\tsrc: url('IRANSansX-Bold.woff') format('woff'),   \r\n\turl('IRANSansX-Bold.woff2') format('woff2');\t \r\n}\r\n\r\n@font-face {\r\n\tfont-family: IRANSansX;\r\n\tfont-style: normal;\r\n\tfont-weight: 400;\r\n\tsrc: url('IRANSansX-Regular.woff') format('woff'),   \r\n\turl('IRANSansX-Regular.woff2') format('woff2');\t\r\n}\r\n\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
