import Links from "../components/Links";

function LinksContainer(props) {
  if (props.links.length === 0) {
    return (
      <div className="noData">
        <p>متاسفانه سرویس مورد نظر پیدا نشد.</p>
      </div>
    );
  }
  return (
    <div className="linkContainer">
      {props.links.map((link) => (
        <Links
          key={link.key}
          image={link.image}
          text={link.text}
          href={link.href}
	  title={link.title}    
        />
      ))}
    </div>
  );
}

export default LinksContainer;
