function Links(props) {
  return (
    <a href={props.href} title={props.title} className="link" target="_blank" rel="noreferrer">
      <img src={props.image} title={props.title} style={props.style} alt={props.text} width="100" />
      <p title={props.title} > {props.text}</p>
    </a>
  );
}

export default Links;
