import LinksContainer from "./LinkContainer";
import { useState, useRef, useEffect } from "react";

var links = [];

function Body() {
  const inputRef = useRef();
  let result = new Set(links);
  const [filterLinks, setFilterLinks] = useState([...result]);
  const [, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    fetch(
      "/links.json"
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        for (const key in data) {
          const link = {
            id: key,
            ...data[key],
          };
          links.push(link);
        }

        result = new Set(links); 
        setIsLoading(false);
        setFilterLinks(links);
      });
  }, []);

  function searchFilter(event) {
    let key = inputRef.current.value;
    result.clear();
    if (key.length === 0) {
      setFilterLinks([...links]);
    } else {
      links.forEach((item) => {
        if (item.text.indexOf(key) > -1 || item.href?.indexOf(key) > -1) {
          result.add(item);
        }
      });
      setFilterLinks([...result]);
    }
  }

  return (
    <div>
      <header className="header">
        <div className="logo"></div>
        <input
          className="searchBar"
          ref={inputRef}
          type="search"
          name="query"
          placeholder="جستجو"
          id="search"
          onChange={searchFilter}
        />
      </header>
      <main className="main">
        <LinksContainer links={filterLinks} />
      </main>
      <footer className="footer">&copy; رایانش ابری واحد هزاردستان</footer>
    </div>
  );
}

export default Body;
